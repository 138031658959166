import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IAddQty,
  IInventoryBasketStates,
  IDiscount,
  IBasketState,
  IAddCount,
} from '../../../core/Interface/property.interface';

export interface BasketItem extends IAddQty {
  id: string;
  code?: string;
  name: string;
  sellingPrice: number;
  cost: number;
  price: number;
  discount: number;
  isDiscountPercentage: boolean;
  discountValue?: number;
  total: number;
  quanity: number;
  totalQty: number;
  freeQty: number;
  discountAmount: number;
  totalDiscountAmount: number;
}

export interface BasketState extends IInventoryBasketStates {
  items: BasketItem[];
  discount: number;
  discountAsPercentage: boolean;
  finalSummaryStare?: boolean;
  discountAmount: number;
}

const initialState: BasketState = {
  items: [],
  supplierNameOrMobile: '',
  receiptNo: '',
  discount: 0,
  discountAsPercentage: false,
  discountAmount: 0,
};

export const basket = createSlice({
  name: 'purchaseBasket',
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<BasketItem>) => {
      const newItem = { ...action.payload };
      
      const existingItemIndex = state.items.findIndex(
        (item) => item.id === newItem.id
      );

      if (existingItemIndex !== -1) {
        state.items[existingItemIndex] = newItem;
      } else {
        state.items.push(newItem);
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      const findItemIndex = state.items.findIndex(
        (element) => element.id === action.payload
      );
      if (findItemIndex > -1) {
        state = { ...state, items: state.items.splice(findItemIndex, 1) };
      }
    },
    removeBasket: (state) => {
      state = { ...initialState };
      return state;
    },

    addMobileReceiptID: (
      state,
      action: PayloadAction<IInventoryBasketStates>
    ) => {
      state.supplierNameOrMobile = action.payload.supplierNameOrMobile;
      state.receiptNo = action.payload.receiptNo;
    },
    updateProductCount: (state, action: PayloadAction<IAddCount>) => {
      const { id, count, freeQty } = action.payload;
      const findItemIndex = state.items.findIndex(
        (element) => element.id === id
      );
      state.items[findItemIndex].quanity = count;
      state.items[findItemIndex].freeQty = freeQty || 0;
      state.items[findItemIndex].totalQty = (freeQty || 0) + (count || 0);
    },
    addDiscount: (
      state,
      action: PayloadAction<{ discount: number; discountAsPercentage: boolean }>
    ) => {
      state.discount = action.payload.discount;
      state.discountAsPercentage = action.payload.discountAsPercentage;
    },
    addFinalSummaryState: (state, action: PayloadAction<IBasketState>) => {
      state.finalSummaryStare = action.payload.finalSummaryStare;
    },
    setSupplierDetails: (
      state,
      action: PayloadAction<{ supplierNameOrMobile: string; receiptNo: string }>
    ) => {
      state.supplierNameOrMobile = action.payload.supplierNameOrMobile;
      state.receiptNo = action.payload.receiptNo;
    },
    clearBasket: (state) => {
      state.items = [];
      state.supplierNameOrMobile = '';
      state.receiptNo = '';
      state.discount = 0;
      state.discountAsPercentage = false;
    },
  },
});

export const {
  addItem,
  removeItem,
  removeBasket,
  addMobileReceiptID,
  addDiscount,
  updateProductCount,
  addFinalSummaryState,
  setSupplierDetails,
  clearBasket,
} = basket.actions;

export const PurchaseBasketReducer = basket.reducer;

export { initialState as BasketInitialState };
